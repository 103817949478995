import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"menu"} />
      <Helmet>
        <title>Наше меню | Дослідіть наше меню на Yexon</title>
        <meta
          name={"description"}
          content={"Насолоджуйтесь мистецтвом кавової майстерності"}
        />
        <meta property={"og:title"} content={"Наше меню | Дослідіть наше меню на Yexon"} />
        <meta
          property={"og:description"}
          content={"Насолоджуйтесь мистецтвом кавової майстерності"}
        />
        <meta property={"og:image"} content={"https://bet.yexonovist.com/img/1.jpg"} />
        <link
          rel={"shortcut icon"}
          href={"https://bet.yexonovist.com/img/cup.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://bet.yexonovist.com/img/cup.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://bet.yexonovist.com/img/cup.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://bet.yexonovist.com/img/cup.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://bet.yexonovist.com/img/cup.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://bet.yexonovist.com/img/cup.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Наше меню
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Пориньте у наше різноманітне меню, створене для задоволення будь-яких смаків та уподобань. Від класичної кави, що пробуджує почуття, до інноваційних напоїв, що дивують, та чудових ласощів, що доповнюють кожен ковток, тут знайдеться щось для кожного. Запрошуємо вас ознайомитися з нашим асортиментом, де кожна позиція розповідає історію якості та креативності. Якщо ви прагнете комфорту, припливу енергії чи нових смакових відчуттів, Yexon обіцяє щоразу незабутній візит.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Завітайте до нас
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://bet.yexonovist.com/img/4.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Section padding="0 0 0 0" quarkly-title="Statistics-7">
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="40%"
            md-width="100%"
            md-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
          >
            <Image
              src="https://bet.yexonovist.com/img/5.jpg"
              display="block"
              width="auto"
              height="100%"
              max-width="100%"
              object-fit="cover"
              object-position="30% 50%"
              md-height="600px"
              sm-height="400px"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="60%"
            padding="40px 0px 0px 90px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 50px"
            md-width="100%"
            md-padding="0px 35px 80px 35px"
            sm-padding="0px 0 80px 0"
          >
            <Text
              margin="0px 0px 30px 0px"
              color="--dark"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Меню
            </Text>
            <Box
              min-width="100px"
              margin="30px 0px 30px 0px"
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              lg-margin="50px 0px 0px 0px"
              md-margin="40px 0px 0px 0px"
              sm-grid-template-columns="1fr"
              sm-grid-template-rows="auto"
            >
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Кавова класика
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Еспресо: Інтенсивна та насичена, виготовлена зі спеціально відібраних зерен.
                  <br />
                  <br />
                  Американо: М'який і міцний, позачасовий вибір для кавових пуристів.
                  <br />
                  <br />
                  Капучино: Ідеально пінний, з делікатним балансом еспресо та оксамитового молока.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                 Спеціальні сорти кави
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Ванільний Sky Latte: Солодкий варіант класичного лате з домашнім ванільним сиропом.
                  <br />
                  <br />
                  Карамельне маккіато: Багатошаровий смак еспресо, спіненого молока та карамелі.
                  <br />
                  <br />
                  Мокко Луш: Розкішне поєднання какао та еспресо, увінчане збитими вершками.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Улюблені напої до чаювання
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Зелений чай "Дзен": Багатий антиоксидантами і тонко освіжає.
                  <br />
                  <br />
                  Ромашковий спокій: Заспокійливий і ніжний, ідеально підходить для релаксації.
                  <br />
                  <br />
                  Ерл Грей Класичний: Вишуканий бленд з відтінками бергамоту.

                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                 Сезонні пропозиції
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Гарбузовий лате зі спеціями: Осінь у чашці, зі справжнім гарбузом та прянощами.
                  <br />
                  <br />
                  Зимовий м'ятний мокко: підбадьорлива м'ята в поєднанні з шоколадом.
                  <br />
                  <br />
                  Чай "Весняний цвіт": Квітковий і легкий, оспівує свіжість весни.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                 Легкі закуски
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Свіжоспечені круасани: Маслянисті та хрусткі, випікаються щодня.
                  <br />
                  <br />
                  Бутерброди ремісничого виробництва: Зроблені з вишуканих інгредієнтів на свіжоспеченому хлібі.
                  <br />
                  <br />
                  Сезонні фруктові парфе: Свіжі, соковиті, увінчані вершковим йогуртом.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Додаткові послуги
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Кавові майстер-класи: Дізнайтеся секрети приготування кави від наших бариста-експертів.
                  <br />
                  <br />
                  Обмін книжками: Діліться та відкривайте для себе нові книжки в нашій спільноті.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0px 60px 0px"
        quarkly-title="Advantages/Features-24"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
        >
          <Text
            margin="0px 0px 48px 0px"
            color="--dark"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            lg-width="100%"
          >
            Створіть свій ідеальний візит
          </Text>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 32px 0px"
            lg-width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
          >
            <Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
              <Text
                margin="0px 0px 0px 0px"
                color="--greyD3"
                font="--base"
                lg-text-align="left"
              >
                Нехай кав'ярня Yexon стане вашим прихистком для чудової кави, смачних ласощів та теплої атмосфери. Відкрийте для себе наступний улюблений пункт меню разом з нами вже сьогодні!
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
